import { QuestionType } from '@innedit/innedit-type';
import { navigate, PageProps } from 'gatsby';
import { QuestionData } from 'packages/innedit';
import React, { FC } from 'react';

import HOCGroup from '~/components/Group/HOC';
import Item from '~/components/List/Item/Question';
import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import ListBody from '~/containers/Espace/List/Body';
import question from '~/params/question.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageQuestionUpdate: FC<PageProps & EspaceProps & UserProps> = props => {
  // const [doc, setDoc] = useState<DocumentType<QuestionType>>();
  const {
    espace,
    location,
    user,
    params: { espaceId, questionId },
  } = props;

  const model = new QuestionData({ espaceId: espace.id, params: question });

  // useEffect(() => {
  //   let isMounted = true;
  //   const unsub = model.watchById(questionId, snapshot => {
  //     if (isMounted) {
  //       setDoc(snapshot);
  //     }
  //   });
  //
  //   return () => {
  //     isMounted = false;
  //     if (unsub) {
  //       unsub();
  //     }
  //   };
  // });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          displayDescription
          displayFeatured
          docId={questionId}
          model={model}
          type="update"
        >
          <HOCGroup
            addOnClick={() =>
              navigate(
                `/espaces/${espaceId}/questions/create?parent=${questionId}`,
              )
            }
            bodyProps={{
              className: 'p-0',
            }}
            title="Sous-questions"
          >
            <ListBody<QuestionType, QuestionData>
              allowSorting
              itemList={Item}
              model={
                new QuestionData({
                  espaceId,
                  orderDirection: 'desc',
                  orderField: 'datetime',
                  params: question,
                  parentId: questionId,
                  wheres: {
                    parent: questionId,
                  },
                })
              }
              search={location.search}
              title="Sous-tâches"
              user={user}
            />
          </HOCGroup>
        </Form>
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageQuestionUpdate);
